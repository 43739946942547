/* eslint-disable */

<template>
  <div id="Lgx245739812367548921">
    <vue-sortable :options="options" @drag:start="startedDrag" @drag:stop="stoppedDrag" @sortable:sort="sort">
      <div class="vx-col w-full p-5 bg-dark main-border mb-5 rounded-lg" :class="{ 'cursor-drag': draggingCard === true }">
        <div class="flex justify-between pb-4 items-center">
          <img src="https://connect-cdn.intellectualpoint.com/assets/images/logos/ip-cyber.png" width="125px" />
          <h2>Identity Access Management Division</h2>
        </div>

        <div
          class="vx-col w-full bg-light p-4 px-3 rounded-lg main-border"
          style="min-height: 130px"
          v-for="(user, index) in shuffledUsers"
          :key="user.id"
          v-if="currentIndex === index"
        >
          <div class="flex flex-row w-full gap-x-3">
            <div class="flex" style="min-width: 100px">
              <vue-draggable-container :id="'wb' + user.id" class="wordBank">
                <img
                  class="dragBlockImageUsed answerImage"
                  v-if="isAvatarUsed(user.id)"
                  :item-id="user.id"
                  :src="user.image"
                  width="100px"
                  style="min-height: 100px !important"
                />
                <img
                  class="dragBlockImage answerImage"
                  v-else
                  :src="user.image"
                  width="100px"
                  :item-id="user.id"
                  style="min-height: 100px !important"
                />
              </vue-draggable-container>
            </div>

            <div class="flex flex-col w-full">
              <div class="flex flex-row justify-between">
                <div class="flex items-center gap-x-4">
                  <h2 class="flex items-center">{{ user.name }}</h2>
                  <span class="main-border bg-light rounded-full py-1 px-3">Employee {{ currentIndex + 1 }} of 10</span>
                </div>

                <div class="flex gap-x-2">
                  <span
                    class="rounded-lg p-2 flex items-center bg-danger"
                    :class="{ 'opacity-50 cursor-auto': currentIndex === 0, 'cursor-pointer': currentIndex !== 0 }"
                    @click="previousUser()"
                  >
                    <font-awesome-icon icon="fa-regular fa-arrow-left" class="h-5 w-5" />
                  </span>

                  <p class="flex items-center justify-center text-lg" style="width: 40px">{{ currentIndex + 1 }}/10</p>

                  <span
                    class="rounded-lg p-2 flex items-center bg-success"
                    :class="{ 'opacity-50 cursor-auto': currentIndex === 9, 'cursor-pointer': currentIndex !== 9 }"
                    @click="nextUser()"
                  >
                    <font-awesome-icon icon="fa-regular fa-arrow-right" class="h-5 w-5" />
                  </span>
                </div>
              </div>

              <h4 class="mt-3 text-dark font-light">{{ user.desc }}</h4>
            </div>
          </div>
        </div>

        <div class="w-full grid grid-cols-5 gap-x-4 mt-8" @click="click_remove">
          <div class="w-full bg-light main-border rounded-lg h-fit">
            <div class="flex items-center justify-center bg-light main-bottom-border p-2 text-center rounded-t-lg gap-x-2">
              <h4>RBAC</h4>
              <font-awesome-icon
                icon="fa-regular fa-circle-info"
                class="h-5 w-5 text-dark cursor-pointer"
                @click="rbacHelpActive = !rbacHelpActive"
              />
            </div>

            <div class="grid grid-cols-2 gap-3 p-3 justify-between">
              <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg answerImages" id="input1" />
              <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg answerImages" id="input2" />
            </div>
          </div>

          <div class="w-full bg-light main-border rounded-lg h-fit">
            <div class="flex items-center justify-center bg-light main-bottom-border p-2 text-center rounded-t-lg gap-x-2">
              <h4>RuBAC</h4>
              <font-awesome-icon
                icon="fa-regular fa-circle-info"
                class="h-5 w-5 text-dark cursor-pointer"
                @click="rubacHelpActive = !rubacHelpActive"
              />
            </div>
            <div class="grid grid-cols-2 gap-3 p-3 justify-between">
              <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg answerImages" id="input3" />
              <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg answerImages" id="input4" />
            </div>
          </div>

          <div class="w-full bg-light main-border rounded-lg h-fit">
            <div class="flex items-center justify-center bg-light main-bottom-border p-2 text-center rounded-t-lg gap-x-2">
              <h4>DAC</h4>
              <font-awesome-icon
                icon="fa-regular fa-circle-info"
                class="h-5 w-5 text-dark cursor-pointer"
                @click="dacHelpActive = !dacHelpActive"
              />
            </div>
            <div class="grid grid-cols-2 gap-3 p-3 justify-between">
              <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg answerImages" id="input5" />
              <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg answerImages" id="input6" />
            </div>
          </div>

          <div class="w-full bg-light main-border rounded-lg h-fit">
            <div class="flex items-center justify-center bg-light main-bottom-border p-2 text-center rounded-t-lg gap-x-2">
              <h4>MAC</h4>
              <font-awesome-icon
                icon="fa-regular fa-circle-info"
                class="h-5 w-5 text-dark cursor-pointer"
                @click="macHelpActive = !macHelpActive"
              />
            </div>
            <div class="grid grid-cols-2 gap-3 p-3 justify-between">
              <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg answerImages" id="input7" />
              <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg answerImages" id="input8" />
            </div>
          </div>

          <div class="w-full bg-light main-border rounded-lg h-fit">
            <div class="flex items-center justify-center bg-light main-bottom-border p-2 text-center rounded-t-lg gap-x-2">
              <h4>ABAC</h4>
              <font-awesome-icon
                icon="fa-regular fa-circle-info"
                class="h-5 w-5 text-dark cursor-pointer"
                @click="abacHelpActive = !abacHelpActive"
              />
            </div>
            <div class="grid grid-cols-2 gap-3 p-3 justify-between">
              <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg answerImages" id="input9" />
              <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg answerImages" id="input10" />
            </div>
          </div>
        </div>
      </div>

      <!-- <component
        :is="quizRenderer"
        ref="quizRenderer"
        :data="{ simData: data, quizData: quizData }"
        v-on:simulation_quiz_correct="emitQuizCorrect"
      /> -->
    </vue-sortable>

    <movable-popup
      refVal="movableRBACPopup"
      title="What is RBAC? - Drag Here To Move"
      :active="rbacHelpActive"
      v-on:close="rbacHelpActive = false"
    >
      <template v-slot:content>
        <div>
          <h2 class="mt-1">Role Based Access Control (RBAC)</h2>
          <p class="text-dark mt-2">
            Role-Based Access Control (RBAC) is an access control model where access permissions are tied to roles, and not individual
            users. Users are assigned roles based on their job responsibilities, and access is granted according to the permissions
            associated with these roles.
          </p>

          <h4 class="mt-4 mb-1 main-top-border pt-4">Example</h4>
          <span class="text-dark"
            >Imagine you have a clubhouse with different jobs like a superhero clubhouse. Each superhero has a specific role - Spider-Man is
            the "Web-Slinger," and Iron Man is the "Tech Genius." Only those with the right role can use the special gadgets and rooms. RBAC
            is like having specific keys for each superhero's role.</span
          >

          <h4 class="mt-4 pt-4 mb-2 main-top-border">Keywords</h4>
          <div class="grid grid-cols-4 gap-x-3">
            <span class="px-4 py-1 bg-dark main-border text-center rounded-lg">Roles</span>
            <span class="px-4 py-1 bg-dark main-border text-center rounded-lg">Job Responsibilities</span>
            <span class="px-4 py-1 bg-dark main-border text-center rounded-lg">Access Permissions</span>
            <span class="px-4 py-1 bg-dark main-border text-center rounded-lg">User Groups</span>
          </div>
        </div>
      </template>
    </movable-popup>

    <movable-popup
      refVal="movableRUBACPopup"
      title="What is RuBAC? - Drag Here To Move"
      :active="rubacHelpActive"
      v-on:close="rubacHelpActive = false"
    >
      <template v-slot:content>
        <div>
          <h2 class="mt-1">Rule Based Access Control (RuBAC)</h2>
          <p class="text-dark mt-2">
            Rule-Based Access Control (RBAC) is an access control model where access decisions are based on a set of rules defined by the
            system administrator. These rules specify conditions under which access is granted or denied.
          </p>

          <h4 class="mt-4 mb-1 main-top-border pt-4">Example</h4>
          <span class="text-dark"
            >Think of your room at home. Your parents set rules like "clean up before bedtime" or "no snacks before dinner." These rules
            help decide what you can and can't do in your room. In the digital world, RBAC is like having computer rules that decide what
            people can do based on certain conditions.</span
          >

          <h4 class="mt-4 pt-4 mb-2 main-top-border">Keywords</h4>
          <div class="grid grid-cols-4 gap-x-3">
            <span class="px-4 py-1 bg-dark main-border text-center rounded-lg">Access Rules</span>
            <span class="px-4 py-1 bg-dark main-border text-center rounded-lg">Conditions</span>
            <span class="px-4 py-1 bg-dark main-border text-center rounded-lg">System Admin</span>
            <span class="px-4 py-1 bg-dark main-border text-center rounded-lg">Policies</span>
          </div>
        </div>
      </template>
    </movable-popup>

    <movable-popup
      refVal="movableMACPopup"
      title="What is MAC? - Drag Here To Move"
      :active="macHelpActive"
      v-on:close="macHelpActive = false"
    >
      <template v-slot:content>
        <div>
          <h2 class="mt-1">Mandatory Access Control (MAC)</h2>
          <p class="text-dark mt-2">
            Mandatory Access Control (MAC) is an access control model where access permissions are determined by the security policy and are
            not subject to the discretion of users or system administrators. It is often used in environments with high-security
            requirements.
          </p>

          <h4 class="mt-4 mb-1 main-top-border pt-4">Example</h4>
          <span class="text-dark"
            >Picture a library where books are labeled as "Easy," "Medium," or "Hard." Only kids with the right level can borrow certain
            books. In the digital world, MAC is like having labels on computer stuff, and you can only use things that match your
            label.</span
          >

          <h4 class="mt-4 pt-4 mb-2 main-top-border">Keywords</h4>
          <div class="grid grid-cols-4 gap-x-3">
            <span class="px-4 py-1 bg-dark main-border text-center rounded-lg">Security Policy</span>
            <span class="px-4 py-1 bg-dark main-border text-center rounded-lg">Clearance Levels</span>
            <span class="px-4 py-1 bg-dark main-border text-center rounded-lg">Labels</span>
            <span class="px-4 py-1 bg-dark main-border text-center rounded-lg">Government, Military</span>
          </div>
        </div>
      </template>
    </movable-popup>

    <movable-popup
      refVal="movableDACPopup"
      title="What is DAC? - Drag Here To Move"
      :active="dacHelpActive"
      v-on:close="dacHelpActive = false"
    >
      <template v-slot:content>
        <div>
          <h2 class="mt-1">Discretionary Access Control (DAC)</h2>
          <p class="text-dark mt-2">
            Discretionary Access Control (DAC) is an access control model where individual users have control over their own objects and can
            determine who has access to those objects. Access permissions are at the discretion of the object owner.
          </p>

          <h4 class="mt-4 mb-1 main-top-border pt-4">Example</h4>
          <span class="text-dark"
            >Think of your toy box. You decide who can play with which toys. If your friend wants to play with a specific toy, they need to
            ask you for permission. DAC is like you deciding who can use your toys and who can't.</span
          >

          <h4 class="mt-4 pt-4 mb-2 main-top-border">Keywords</h4>
          <div class="grid grid-cols-2 gap-3">
            <span class="px-4 py-1 bg-dark main-border text-center rounded-lg">Individual User Control</span>
            <span class="px-4 py-1 bg-dark main-border text-center rounded-lg">Object Owner</span>
            <span class="px-4 py-1 bg-dark main-border text-center rounded-lg">Access Permissions</span>
            <span class="px-4 py-1 bg-dark main-border text-center rounded-lg">User's Discretion</span>
          </div>
        </div>
      </template>
    </movable-popup>

    <movable-popup
      refVal="movableABACPopup"
      title="What is ABAC? - Drag Here To Move"
      :active="abacHelpActive"
      v-on:close="abacHelpActive = false"
    >
      <template v-slot:content>
        <div>
          <h2 class="mt-1">Attribute-Based Access Control (ABAC)</h2>
          <p class="text-dark mt-2">
            Attribute-Based Access Control (ABAC) is an access control model where access decisions are based on attributes associated with
            users, resources, and the environment. These attributes can include user roles, department, time of access, and more.
          </p>

          <h4 class="mt-4 mb-1 main-top-border pt-4">Example</h4>
          <span class="text-dark"
            >Imagine a treasure chest, and to open it, you need two keys: one from the Pirate Captain and one from the Treasure Map Holder.
            ABAC is like having different keys based on special things - only when you have the right combination of keys can you open the
            treasure chest.</span
          >

          <h4 class="mt-4 pt-4 mb-2 main-top-border">Keywords</h4>
          <div class="grid grid-cols-3 gap-3">
            <span class="px-4 py-1 bg-dark main-border text-center rounded-lg">Attributes</span>
            <span class="px-4 py-1 bg-dark main-border text-center rounded-lg">Policies</span>
            <span class="px-4 py-1 bg-dark main-border text-center rounded-lg">Environment</span>
            <span class="px-4 py-1 bg-dark main-border text-center rounded-lg">Dynamic Access Control</span>
          </div>
        </div>
      </template>
    </movable-popup>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import Prism from 'vue-prism-component';
import shuffle from 'shuffle-array';
import MovablePopup from '../../../../../../../../components/popup/MovablePopup';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
      draggingCard: false,
      clonedNode: null,
      usedAvatars: [],
      currentSourceContainerId: null,
      originalNodeId: null,
      origSourceContainer: null,

      rbacHelpActive: false,
      rubacHelpActive: false,
      dacHelpActive: false,
      macHelpActive: false,
      abacHelpActive: false,

      currentIndex: 0,

      quizComplete: false,

      users: [
        {
          id: '1',
          name: 'Christine Sanders',
          image: 'https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/245739812367548921/woman1.png',
          desc: 'Christine has been hired on as a Security Analyst with IP Cyber. As a Security Analyst, Christine will be working with government clients, and she must have the capability to deal with cleared information, security labels, and sensitive information. IP Cyber expects all Security Analysts to have this capability and as such, assigns them the appropriate accesses.',
        },
        {
          id: '2',
          name: 'Harry Jones',
          image: 'https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/245739812367548921/mane1.png',
          desc: 'Harry is a seasoned employee for IP Cyber and recently transferred to a new department to join the accounting team as a Financial Specialist. IP Cyber wants all Financial Specialists to have the same accesses to financial information to ensure the company runs smoothly, and all bills are paid on time.',
        },
        {
          id: '3',
          name: 'Avery Wise',
          image: 'https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/245739812367548921/woman2.png',
          desc: "As a professor for MIT, Avery plays a crucial role in the grading process. The university's system mandates that only professors have the authority to submit final grades for courses. This rule is in place to uphold the integrity of students' academic records, ensuring that unauthorized changes are prevented.",
        },
        {
          id: '4',
          name: 'Mark Davids',
          image: 'https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/245739812367548921/mane2.png',
          desc: 'Mark is a project lead for a new web-dashboard for IP Cyber employees. He oversees the changes and updates provided by the development team. Over the weekend, a crucial bug was found. Mark reported to the office immediately but was not able to access the tools he needed. He realized the company has a strict policy restricting access to resources outside of business hours. Therefore, Mark cannot apply a software fix and address the bug until normal office hours resume on Monday.',
        },
        {
          id: '5',
          name: 'Olivia Silva',
          image: 'https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/245739812367548921/woman3.png',
          desc: 'Olivia is a software developer working on a project for IP Cyber. During her development, she runs into some problems with accessing sensitive data she needs to continue her development. To properly develop her programs, she needs access to the sensitive data set so that she can use it for testing purposes. When she requests access from the Account Manager, he denies her, telling her that her current security level will not allow her to access the sensitive data.',
        },
        {
          id: '6',
          name: 'Thomas Watts',
          image: 'https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/245739812367548921/mane3.png',
          desc: "Thomas is an IP Cyber employee tasked with managing sensitive information about a major upcoming project. An employee on his team comes to him and asks for access to the information so that he can work on the project from home. Thomas reviews the employee's security level and determines that the employee is not allowed to access the information.",
        },
        {
          id: '7',
          name: 'Emory Dunn',
          image: 'https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/245739812367548921/woman4.png',
          desc: 'Emory is an Access control Manager with IP Cyber tasked with maintaining access controls over a cloud storage system. One of the team members reaches out to Emory and requests login credentials for the cloud storage server. Emory looks at the different properties required to access the server and notices that the employee requesting access does not meet several of the requirements. Emory denied the employee’s request, ensuring a secure and organized system while respecting the limitations defined by the employer.',
        },
        {
          id: '8',
          name: 'Andrew McCormick',
          image: 'https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/245739812367548921/mane4.png',
          desc: "Andrew, a Project Coordinator at IP Cyber, oversees projects spanning multiple departments. A marketing department employee approached Andrew about getting access to a highly sensitive research and development project. Andrew examined the employee's attributes, including department, project affiliation, and role. Despite the employee's legitimate involvement in other projects, he does not meet many of the attributes required to access the R&D initiative data. Andrew denies the access, upholding security and ensuring access aligns with the companies' policies.",
        },
        {
          id: '9',
          name: 'Michelle Osborne',
          image: 'https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/245739812367548921/woman5.png',
          desc: 'Michelle is the Marketing Lead for IP Cyber. Within her team, individual users can set permissions on their shared documents, deciding who can view, edit, or comment on their work.',
        },
        {
          id: '10',
          name: 'Toby Stuart',
          image: 'https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/245739812367548921/mane5.png',
          desc: 'Toby is the Founder and CEO of IP Cyber, he has full control over all company resources and is able to make any and all changes he wishes.',
        },
      ],

      quizData: {
        questions: 10,
        questionData: [
          {
            question:
              'In a software development company, employees are granted access based on predefined rules specifying which servers and databases they can interact with. Which access control type does this represent?',
            choices: [
              { text: 'Role-Based Access Control', abrv: 'RBAC' },
              { text: 'Rule-Based Access Control', abrv: 'RuBAC' },
              { text: 'Mandatory Access Control', abrv: 'MAC' },
              { text: 'Attribute-Based Access Control', abrv: 'ABAC' },
            ],
            answer: 3,
          },
          {
            question:
              "Within a government agency, information is classified as 'Top Secret,' 'Secret,' and 'Unclassified.' Access to this information is determined by an individual's security clearance level. What type of access control is being used?",
            choices: [
              { text: 'Discretionary Access Control', abrv: 'DAC' },
              { text: 'Mandatory Access Control', abrv: 'MAC' },
              { text: 'Role-Based Access Control', abrv: 'RBAC' },
              { text: 'Attribute-Based Access Control', abrv: 'ABAC' },
            ],
            answer: 1,
          },
          {
            question:
              'In a university system, professors control access to their lecture materials. They can grant or revoke access to students based on enrollment status or academic performance. Which access control type is this?',
            choices: [
              { text: 'Discretionary Access Control', abrv: 'DAC' },
              { text: 'Rule-Based Access Control', abrv: 'RuBAC' },
              { text: 'Attribute-Based Access Control', abrv: 'ABAC' },
              { text: 'Mandatory Access Control', abrv: 'MAC' },
            ],
            answer: 0,
          },
          {
            question:
              'In a healthcare organization, doctors, nurses, and administrative staff have different levels of access to patient records. Doctors can view and update medical histories, while administrative staff can only access billing information. What type of access control is in place?',
            choices: [
              { text: 'Role-Based Access Control', abrv: 'RBAC' },
              { text: 'Attribute-Based Access Control', abrv: 'ABAC' },
              { text: 'Discretionary Access Control', abrv: 'DAC' },
              { text: 'Rule-Based Access Control', abrv: 'RuBAC' },
            ],

            answer: 0,
          },
          {
            question:
              "In a financial institution, access to certain financial reports is based on an employee's job title. Only employees with the role of 'Financial Analyst' have permission to view and modify these reports. What access control type is being used?",
            choices: [
              { text: 'Mandatory Access Control', abrv: 'MAC' },
              { text: 'Attribute-Based Access Control', abrv: 'ABAC' },
              { text: 'Discretionary Access Control', abrv: 'DAC' },
              { text: 'Role-Based Access Control', abrv: 'RBAC' },
            ],
            answer: 3,
          },
          {
            question:
              "In a military base, access to different sections is determined by a combination of the soldier's rank, department, and specific clearances. Which access control type is being used?",
            choices: [
              { text: 'Discretionary Access Control', abrv: 'DAC' },
              { text: 'Role-Based Access Control', abrv: 'RBAC' },
              { text: 'Attribute-Based Access Control', abrv: 'ABAC' },
              { text: 'Mandatory Access Control', abrv: 'MAC' },
            ],
            answer: 2,
          },
          {
            question:
              'Within a chemical laboratory, certain researchers are allowed to access hazardous materials storage based on their training certifications. What type of access control is this?',
            choices: [
              { text: 'Role-Based Access Control', abrv: 'RBAC' },
              { text: 'Attribute-Based Access Control', abrv: 'ABAC' },
              { text: 'Mandatory Access Control', abrv: 'MAC' },
              { text: 'Discretionary Access Control', abrv: 'DAC' },
            ],
            answer: 1,
          },
          {
            question:
              'In a government intelligence agency, specific individuals are granted access to classified information based on their job responsibilities. What access control type is in place?',
            choices: [
              { text: 'Discretionary Access Control', abrv: 'DAC' },
              { text: 'Mandatory Access Control', abrv: 'MAC' },
              { text: 'Role-Based Access Control', abrv: 'RBAC' },
              { text: 'Attribute-Based Access Control', abrv: 'ABAC' },
            ],
            answer: 1,
          },
          {
            question:
              "Within a large corporation, employees can access the company's intranet and shared documents based on their department and job role. Which access control type is this?",
            choices: [
              { text: 'Attribute-Based Access Control', abrv: 'ABAC' },
              { text: 'Discretionary Access Control', abrv: 'DAC' },
              { text: 'Role-Based Access Control', abrv: 'RBAC' },
              { text: 'Rule-Based Access Control', abrv: 'RuBAC' },
            ],
            answer: 2,
          },
          {
            question:
              "In a nuclear power plant, access to the control room is restricted based on an employee's level of training, security clearance, and the specific role they play in emergency response. What type of access control is being employed?",
            choices: [
              { text: 'Mandatory Access Control', abrv: 'MAC' },
              { text: 'Attribute-Based Access Control', abrv: 'ABAC' },
              { text: 'Rule-Based Access Control', abrv: 'RuBAC' },
              { text: 'Discretionary Access Control', abrv: 'DAC' },
            ],
            answer: 0,
          },
        ],
      },

      options: {
        draggable: '.dragBlockImage',
        dropzone: '.dropzoneStandard',
      },
    };
  },
  computed: {
    shuffledUsers() {
      return shuffle(this.users);
    },
    quizRenderer() {
      // eslint-disable-next-line
      return () => import(`../../../SimulationQuizRenderer.vue`);
    },
    isAvatarUsed() {
      return (userId) => this.usedAvatars.includes(userId);
    },
  },
  methods: {
    emitQuizCorrect() {
      this.quizComplete = true;
      return this.$emit('simulation_quiz_correct', this.quizData.questions);
    },
    startedDrag(x) {
      if (x.data.source.className.includes('stillBlockDataClassification')) {
      } else {
        x.data.source.className += ' currently-dragging-image';
        const uuid = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        this.draggingCard = true;
        x.data.originalSource.id = uuid;
        const originalNode = x.data.originalSource;
        this.originalNodeId = x.data.source.getAttribute('item-id');

        this.clonedNode = originalNode.cloneNode(true);
        this.clonedNode.classList.add('clonedElement');
        this.clonedNode.classList.remove('dragBlockImage');
        this.clonedNode.classList.add('dragBlockImageUsed');
        this.clonedNode.id += '-cloned';
        originalNode.parentNode.insertBefore(this.clonedNode, originalNode.nextSibling);
      }
    },
    stoppedDrag() {
      this.draggingCard = false;
    },
    sort(x) {
      const overContainer = x.data.dragEvent.data.overContainer;
      this.origSourceContainer = x.data.dragEvent.data.originalSource;
      if (overContainer.classList.contains('wordBank')) {
        if (overContainer.children.length > 0) {
          return x.cancel();
        }
      }
      if (overContainer && overContainer.classList.contains('dropzone')) {
        if (overContainer.children.length > 0) {
          return x.cancel();
        }

        this.currentSourceContainerId = this.origSourceContainer.id;

        if (!this.usedAvatars.includes(this.originalNodeId)) {
          this.usedAvatars.push(this.originalNodeId);
        }

        setTimeout(() => {
          document.getElementById(`${this.currentSourceContainerId}-cloned`).style.display = 'inline-flex';
          document.getElementById(this.currentSourceContainerId).classList.remove('dragBlockImageUsed');
          document.getElementById(this.currentSourceContainerId).classList.remove('dragBlockImage');
          document.getElementById(this.currentSourceContainerId).classList.add('stillBlockDataClassification');
        }, 2);

        return;
      }
      document.getElementById(`${this.origSourceContainer.id}-cloned`).outerHTML = '';
    },
    nextUser() {
      if (this.currentIndex < 9) {
        this.currentIndex++;
      }
    },
    previousUser() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      }
    },
    click_remove(x) {
      const target = x.target;
      const targetID = target.getAttribute('item-id');

      if (target.classList.contains('stillBlockDataClassification')) {
        const indexToRemove = this.usedAvatars.findIndex((item) => item === targetID);

        if (indexToRemove !== -1) {
          this.usedAvatars.splice(indexToRemove, 1);
        }

        const elementsWithSameItemId = document.querySelectorAll(`[item-id="${targetID}"]`);
        elementsWithSameItemId.forEach((element) => {
          element.classList.remove('dragBlockImageUsed');
          element.classList.add('dragBlockImage');
        });

        target.outerHTML = '';
      }
    },

    markSimulation() {
      const inputChildren = [];

      for (let i = 1; i <= 10; i++) {
        const inputChild = document.getElementById(`input${i}`).querySelector('img');
        inputChildren.push(inputChild);
      }

      let totalScore = 0;

      // RBAC SCORING
      if (
        inputChildren[0] &&
        inputChildren[0].src !== null &&
        (inputChildren[0].src.includes('woman1') || inputChildren[0].src.includes('mane1'))
      ) {
        document.getElementById('input1').style.borderColor = '#28C76F';
        document.getElementById('input1').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input1').style.borderColor = '#fd4445';
        document.getElementById('input1').style.backgroundColor = '#fd4445';
      }

      if (
        inputChildren[1] &&
        inputChildren[1].src !== null &&
        (inputChildren[1].src.includes('mane1') || inputChildren[1].src.includes('woman1'))
      ) {
        document.getElementById('input2').style.borderColor = '#28C76F';
        document.getElementById('input2').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input2').style.borderColor = '#fd4445';
        document.getElementById('input2').style.backgroundColor = '#fd4445';
      }

      // RUBAC SCORING
      if (
        inputChildren[2] &&
        inputChildren[2].src !== null &&
        (inputChildren[2].src.includes('woman2') || inputChildren[2].src.includes('mane2'))
      ) {
        document.getElementById('input3').style.borderColor = '#28C76F';
        document.getElementById('input3').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input3').style.borderColor = '#fd4445';
        document.getElementById('input3').style.backgroundColor = '#fd4445';
      }

      if (
        inputChildren[3] &&
        inputChildren[3].src !== null &&
        (inputChildren[3].src.includes('mane2') || inputChildren[3].src.includes('woman2'))
      ) {
        document.getElementById('input4').style.borderColor = '#28C76F';
        document.getElementById('input4').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input4').style.borderColor = '#fd4445';
        document.getElementById('input4').style.backgroundColor = '#fd4445';
      }

      // DAC SCORING
      if (
        inputChildren[4] &&
        inputChildren[4].src !== null &&
        (inputChildren[4].src.includes('mane5') || inputChildren[4].src.includes('woman5'))
      ) {
        document.getElementById('input5').style.borderColor = '#28C76F';
        document.getElementById('input5').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input5').style.borderColor = '#fd4445';
        document.getElementById('input5').style.backgroundColor = '#fd4445';
      }

      if (
        inputChildren[5] &&
        inputChildren[5].src !== null &&
        (inputChildren[5].src.includes('woman5') || inputChildren[5].src.includes('mane5'))
      ) {
        document.getElementById('input6').style.borderColor = '#28C76F';
        document.getElementById('input6').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input6').style.borderColor = '#fd4445';
        document.getElementById('input6').style.backgroundColor = '#fd4445';
      }

      // MAC SCORING
      if (
        inputChildren[6] &&
        inputChildren[6].src !== null &&
        (inputChildren[6].src.includes('mane3') || inputChildren[6].src.includes('woman3'))
      ) {
        document.getElementById('input7').style.borderColor = '#28C76F';
        document.getElementById('input7').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input7').style.borderColor = '#fd4445';
        document.getElementById('input7').style.backgroundColor = '#fd4445';
      }

      if (
        inputChildren[7] &&
        inputChildren[7].src !== null &&
        (inputChildren[7].src.includes('woman3') || inputChildren[7].src.includes('mane3'))
      ) {
        document.getElementById('input8').style.borderColor = '#28C76F';
        document.getElementById('input8').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input8').style.borderColor = '#fd4445';
        document.getElementById('input8').style.backgroundColor = '#fd4445';
      }

      // ABAC SCORING
      if (
        inputChildren[8] &&
        inputChildren[8].src !== null &&
        (inputChildren[8].src.includes('mane4') || inputChildren[8].src.includes('woman4'))
      ) {
        document.getElementById('input9').style.borderColor = '#28C76F';
        document.getElementById('input9').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input9').style.borderColor = '#fd4445';
        document.getElementById('input9').style.backgroundColor = '#fd4445';
      }

      if (
        inputChildren[9] &&
        inputChildren[9].src !== null &&
        (inputChildren[9].src.includes('woman4') || inputChildren[9].src.includes('mane4'))
      ) {
        document.getElementById('input10').style.borderColor = '#28C76F';
        document.getElementById('input10').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input10').style.borderColor = '#fd4445';
        document.getElementById('input10').style.backgroundColor = '#fd4445';
      }

      // if (this.quizComplete) {
      //   totalScore = totalScore + this.quizData.questions;

      //   if (totalScore === this.dataLocal.simulation.totalScore) {
      //     return this.$emit('simulation_full_correct', totalScore);
      //   } else {
      //     this.$emit('simulation_getting_there', totalScore);
      //   }
      // } else if (totalScore === this.dataLocal.simulation.totalScore - this.quizData.questions) {
      //   return this.$emit('simulation_complete_not_quiz', totalScore);
      // } else {
      //   this.$emit('simulation_getting_there', totalScore);
      // }

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      } else {
        this.$emit('simulation_getting_there', totalScore);
      }
    },
  },
  components: {
    draggable,
    Prism,
    shuffle,
    MovablePopup,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.wordBank {
  min-height: unset !important;
  display: flex;
  align-items: flex-start;
}

.stillBlockDataClassification {
  word-break: break-all;
  background-color: rgba(var(--vs-primary), 1);
  display: inline-flex;
  border-radius: 0.6rem;
  font-size: 1.4rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-weight: 800 !important;
  cursor: url('~@/assets/images/cursor/trash-cursor.png'), auto !important;
  background-color: #011d56 !important;
  border: 1px solid #0053ff !important;
  color: #fff !important;
  width: 100%;
  height: 100%;
}

.answerImages {
  height: 102px;
  width: fit-content;
  min-width: 100px;
  display: flex;
  flex-direction: row;
}
</style>

<style lang="scss">
#Lgx245739812367548921 {
  .draggable-source--is-dragging {
    width: 100px !important;
  }
}
</style>
